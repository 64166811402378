var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分派任务",
            top: "15%",
            visible: _vm.showDialog,
            width: "\n 30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.selectLoading,
                  expression: "selectLoading",
                },
              ],
              ref: "addform",
              attrs: {
                model: _vm.addform,
                rules: _vm.myrules,
                "label-width": "100px",
              },
            },
            [
              _vm.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "指派给", prop: "employeeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.addform.employeeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.addform, "employeeId", $$v)
                            },
                            expression: "addform.employeeId",
                          },
                        },
                        _vm._l(_vm.workerList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.employeeName,
                              value: item.employeeId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "rate" } },
                    [
                      _c(
                        "div",
                        { staticClass: "order-rate" },
                        [
                          _c("el-rate", {
                            model: {
                              value: _vm.addform.rate,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "rate", $$v)
                              },
                              expression: "addform.rate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAdd("addform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }