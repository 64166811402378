<template>
 <div class="my-box">

  <el-dialog title="分派任务" top="15%" :visible.sync="showDialog" width="
  30%">
   <el-form :model="addform" ref="addform" :rules="myrules" label-width="100px" v-loading="selectLoading">
    <el-form-item label="指派给" prop="employeeId" v-if="type==1">
     <el-select v-model="addform.employeeId" clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in workerList" :key="index" :label="item.employeeName" :value="item.employeeId"></el-option>
     </el-select>
    </el-form-item>
 <el-form-item label="评分" prop="rate" v-if="type==3">
   <div class="order-rate">
     <el-rate v-model="addform.rate" ></el-rate>

   </div>
    </el-form-item>

   </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="showDialog=false">取 消</el-button>
    <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>
 </div>
</template>
<script>
import { 
  getWorkerList, 
  updateOrderDetail,
  finishWorkOrder 
  } from "@/api";

export default {
 data() {
  return {
    type:null,
   loading: false,
   showDialog: false,
   selectLoading: false,
   addform: {
    employeeId: null,
    rate:5
   },
   workerList: [],
   item: {},
   myrules: {
    employeeId: [{ required: true, message: "请选择", trigger: "change" }],
   },
  };
 },
 computed: {
  cardTypes() {
   return this.$store.state.cardTypes;
  },
 },
 methods: {
  openSet(item, type) {
   this.item = item;
   this.type=type
   this.showDialog = true;
   this.addform.employeeId=item.employeeId
   this.addform.rate=item.score?item.score:1
   let data = {};
   data.brandId = item.brandId;
   data.hotelId = item.hotelId;
   data.orderType = item.orderType;
   this.getWorkerListReq(data);
  },
  getWorkerListReq(data) {
   this.selectLoading = true;
   console.log(data, "-------------getWorkerList");
   getWorkerList(data)
    .then((res) => {
     console.log(res.data);
     this.selectLoading = false;
     this.workerList = res.data.data;
    })
    .catch((err) => {
     this.selectLoading = false;
    });
  },
  confirmAdd(formName) {
   // addform.province = selectedOptions
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     switch (this.type) {
       case 1:
           this.submitReq();
         break;
        //   case 2:
        //    this.submitReq();
        //  break;
          case 3:
           this.finishWorkOrderReq();
         break;
       default:
         break;
     }
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
  // 分派
  submitReq() {
     let data = {};
     data.orderId = this.item.orderId;
    //  data.orderStatus = this.item.orderStatus ? 2 : 1;
     data.employeeId = this.addform.employeeId;
     console.log(data);
   updateOrderDetail(data)
    .then((res) => {
     console.log(res);
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success('分派成功');
      this.showDialog = false;
      this.$parent.getList();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     console.log(err);
     this.loading = false;
    });
  },
  finishWorkOrderReq(){
    let data = {};
     data.orderId = this.item.orderId;
     data.score = this.addform.rate;
     data.orderStatus = 5
    finishWorkOrder(data).then((res) => {
     console.log(res);
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success('验收成功');
      this.showDialog = false;
      this.$parent.getList();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     console.log(err);
     this.loading = false;
    });
  }
 },
};
</script>
<style lang="scss" scoped>
</style>


