<template>
 <div class="my-box">

  <el-dialog title="发布工单" top="5%" :visible.sync="showDialog" width="40%">
   <el-form :model="addform" ref="addform" :rules="myrules" label-width="100px">
    <el-form-item label="品牌" prop="brandId">
     <el-select v-model="addform.brandId" @change="selectOne" filterable clearable placeholder="请选择">
      <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id+and+item.brandName"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="门店" prop="hotelId">
     <el-select v-model="addform.hotelId" @change="initWorkList" clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in hotelSelectData" :key="index" :label="item.name" :value="item.id+and+item.name"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="工单类型" prop="orderType">
     <el-select v-model="addform.orderType" @change="initWorkList" clearable placeholder="请选择">
      <el-option v-for="(item,index) in orderTypeList" v-show="item.value!=0" :key="index" :label="item.label" :value="item.value"></el-option>
     </el-select>
    </el-form-item>

    <el-form-item label="房间号" prop="roomNumber">
     <el-input v-model.trim="addform.roomNumber" placeholder="请输入房间号" maxlength="15"></el-input>
    </el-form-item>

    <el-form-item label="描述" prop="remark">
     <el-input v-model.trim="addform.remark" placeholder="请输入工作描述" maxlength="15"></el-input>
    </el-form-item>
    <el-form-item label="指派给" prop="employeeId">
     <el-select v-model="addform.employeeId" clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in workerList" :key="index" :label="item.employeeName" :value="item.employeeId"></el-option>
     </el-select>
    </el-form-item>
   </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="showDialog=false">取 消</el-button>
    <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>
 </div>
</template>
<script>
import { getWorkerList, getHotelSelect, workOrderAdd } from "@/api";
import { secondGetLongTime } from "@/api/common.js";

export default {
 props: ["brandSelectData"],
 data() {
  return {
   and: "-and-",
   loading: false,
   showDialog: false,
   addform: {
    hotelId: null,
    brandId: null,
    orderType: null,
    roomNumber: null,
    remark: null,
    employeeId: null,
   },
   workerList: [],
   hotelSelectData: [],
   myrules: {
    hotelId: [{ required: true, message: "请选择", trigger: "change" }],
    brandId: [{ required: true, message: "请选择", trigger: "change" }],
    orderType: [{ required: true, message: "请选择", trigger: "change" }],
    roomNumber: [{ required: true, message: "请输入房间号", trigger: "blur" }],
    remark: [{ required: true, message: "请描述一下工作", trigger: "blur" }],
    employeeId: [{ required: true, message: "请选择", trigger: "change" }],
   },
  };
 },
 computed: {
  orderTypeList() {
   return this.$store.state.orderTypeList;
  },
 },
 methods: {
  getWorkerListReq() {
   console.log(this.addform);

   if (!this.addform.hotelId || !this.addform.orderType) return;
   let data = {};
   let hotelData = this.addform.hotelId.split(this.and);
   data.hotelId = hotelData[0];
   data.orderType = this.addform.orderType;
   this.selectLoading = true;
   console.log(data, "-------------getWorkerList");
   getWorkerList(data)
    .then((res) => {
     console.log(res.data);
     this.selectLoading = false;
     this.workerList = res.data.data;
    })
    .catch((err) => {
     this.selectLoading = false;
    });
  },
  initWorkList() {
   this.workerList = null;
   this.addform.employeeId = null;
   this.getWorkerListReq();
  },
  selectOne(val) {
   this.addform.hotelId = null;
   this.addform.employeeId = null;
   let id = val.split(this.and)[0];
   getHotelSelect(id).then((res) => {
    if (res.data.data) {
     this.hotelSelectData = res.data.data;
     console.log(this.hotelSelectData, "; this.hotelSelectData");
    } else {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   });
  },
  // 时长
  showLongTimes(val) {
   return secondGetLongTime(val);
  },

  openSet() {
   this.showDialog = true;
   this.addform = {
    hotelId: null,
    brandId: null,
    orderType: null,
    roomNumber: null,
    remark: null,
    employeeId: null,
   };
  },
  getData(obj) {
   let data = {};
   let brandData = obj.brandId.split(this.and);
   data.brandId = brandData[0];
   data.brandName = brandData[1];
   let hotelData = obj.hotelId.split(this.and);
   data.hotelId = hotelData[0];
   data.hotelName = hotelData[1];

   let arr = this.workerList.filter(
    (item) => item.employeeId == obj.employeeId
   );
   if (arr.length > 0) {
    let employeeData = arr[0];
    ["employeeId", "employeeName", "cardSn"].forEach((key) => {
     data[key] = employeeData[key];
    });
   }
   ["orderType", "roomNumber", "remark"].forEach((key) => {
    data[key] = obj[key];
   });
   return data;
  },
  confirmAdd(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     let data = this.getData(this.addform);
     console.log(data);
     workOrderAdd(data)
      .then((res) => {
       console.log(res);
       this.loading = false;
       if (res.data.code == "000000") {
        this.$message.success("工单发布成功");
        this.showDialog = false;
        this.$parent.getList();
       } else {
        this.$message.error(res.data.message);
       }
      })
      .catch((err) => {
       console.log(err);
       this.loading = false;
      });
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
 },
};
</script>
<style lang="scss" scoped>
</style>


