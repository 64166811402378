<template>
 <div class="my-box">
  <el-card shadow="always" class="my-card topS">
   <el-form label-width="50px">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="3">
      <el-form-item label="品牌">
       <el-select v-model="searchForm.brandId" @change="selectOne" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="5">
      <el-form-item label="门店">
       <el-select v-model="searchForm.hotelId" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in hotelSelectData" :key="index" :label="item.name" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="房间号" label-width="60px">
       <el-input v-model="searchForm.roomNumber" clearable></el-input>
      </el-form-item>
     </el-col>

     <el-col :span="4">
      <el-form-item label="时间">
       <el-date-picker v-model="searchForm.times" style="width:100%" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" :default-time="['00:00:00', '23:59:59']" placeholder="选择日期">
       </el-date-picker>
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="工单类型" label-width="80px">
       <el-select v-model="searchForm.orderType" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.orderTypeList" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="工单状态" label-width="80px">
       <el-select v-model="searchForm.orderStatus" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.orderStatusList" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="处理人" label-width="80px">
       <el-input v-model="searchForm.employeeName" clearable></el-input>
      </el-form-item>
     </el-col>
    </el-row>
    <el-row>
     <el-col :span="12">
       <el-button size="mini" type="primary" v-if="showAdd" @click="openpopup">添加</el-button>
       <span style="display:inline-block;"></span>
     </el-col>
     <el-col :span="12">
      <div class="grid-content reset-button">
       <el-button size="mini" type="primary" @click="handleSearch">查询</el-button>
       <el-button size="mini" @click="reset">重置</el-button>

      </div>
     </el-col>
    </el-row>

   </el-form>
  </el-card>
  <el-card>
   <el-table :data="tablist" v-loading="loading" id="tab" :height="height" style="width: 100%">
    <el-table-column v-for="(item,i) in execlTab" :key="i"  :width="item.width" :prop="item.value" :align="item.type=='operation'?'center':''" :label="item.label">
     <template slot-scope="scope">
      <div v-if="item.type=='operation'" class="reset-button">
       <el-button type="primary" v-if="showtoOther&&scope.row.orderStatus<4" size="mini" @click="assignTask(scope.row)">分派</el-button>
       <!-- ||scope.row.orderStatus==5 -->
       <el-button type="warning" v-if="showrework&&(scope.row.orderStatus==4)" size="mini" @click="rework(scope.row)">返工</el-button>
       <el-button type="success" v-if="showcheckOrder&&scope.row.orderStatus==4" size="mini" @click="orderFinish(scope.row)">验收</el-button>
       <el-button type="danger" v-if="showDelete" size="mini" @click="del(scope.row)">删除</el-button>
      </div>
      <div v-if="item.solt_TEXT">
       {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
      </div>
      <div v-else>
       {{scope.row[item.value]}}
      </div>
     </template>
    </el-table-column>
   </el-table>
   <div class="page">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination>
   </div>
  </el-card>

  <addView ref="add" :brandSelectData="brandSelectData" />
  <assignTaskView ref="assignTaskView" />
  <messageStatus ref="msgTip" :typeLists="typeLists" />
 </div>
</template>
<script>
import { getLongDateTime, secondGetLongTime, showLabel } from "@/api/common.js";
import {
 getWorkOrderList,
 getBrandSelect,
 getHotelSelect,
 deleteWorkOrder,
 updateOrderDetail,
 finishWorkOrder,
 importOrderTemp,
 workOrderImport,
} from "@/api";
import addView from "./addView";
import assignTaskView from "./assignTaskView";

const typeLists = {};
import { export_json_to_excel } from "@/vendor/Export2Excel.js";

export default {
 components: {
  addView,
  assignTaskView,
 },
 data() {
  return {
   height: null,
   tablist: [],
   execlTab: [
    {
     label: "品牌",
     value: "brandName",
    },
    {
     label: "门店",
     value: "hotelName",
     width: 280,
    },
    {
     label: "房号",
     value: "roomNumber",
    },

    {
     label: "工单类型",
     value: "orderType",
     solt_TEXT: "orderTypeList",
    },
    {
     label: "工单号",
     value: "orderId",
     width: 160,
    },
    {
     label: "描述",
     value: "remark",
    },
    {
     label: "工单状态",
     value: "orderStatus",
     solt_TEXT: "orderStatusList",
    },
    {
     label: "处理人",
     value: "employeeName",
    },
    {
     label: "创建时间",
     value: "createTime",
     width: 150,
    },
    {
     label: "验收时间",
     value: "finishTime",
     width: 150,
    },

    {
     label: "操作",
     type: "operation",
     width: 250,
    },
   ],
   loading: false,
   // 查询的数据
   searchForm: {
    brandId: null,
    hotelId: null,
    roomNumber: null,
    times: null,
    orderType: null,
    orderStatus: null,
    employeeName: null,
   },
   total: 0,
   pageNum: 1,
   pageSize: 10,
   listQuery: {},
   brandSelectData: null,
   hotelSelectData: null,
  };
 },
 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 295;
 },
 computed: {
  typeLists() {
   let obj = typeLists;
   obj.orderTypeList = this.$store.state.orderTypeList;
   obj.orderStatusList = this.$store.state.orderStatusList;
   console.log(obj);
   return obj;
  },
  showDelete: function () {
   return this.$store.state.roleMenu.includes("orderManage:deleteBtn");
  },
  showrework: function () {
   return this.$store.state.roleMenu.includes("orderManage:reworkBtn");
  },
  showcheckOrder: function () {
   return this.$store.state.roleMenu.includes("orderManage:checkOrderBtn");
  },
  showtoOther: function () {
   return this.$store.state.roleMenu.includes("orderManage:toOtherBtn");
  },
  // showExport: function () {
  //  return this.$store.state.roleMenu.includes("orderManage:Export");
  // },
  showAdd: function () {
   return this.$store.state.roleMenu.includes("orderManage:add");
  },
  // showImport: function () {
  //  return this.$store.state.roleMenu.includes("orderManage:ImportAdd");
  // },
 },

 created() {
  this.getList();
  this.initialize();
 },
 methods: {
  //  验收
  orderFinish(item) {
   this.$refs.assignTaskView.openSet(item, 3);
  },
  //  分派
  assignTask(item) {
   this.$refs.assignTaskView.openSet(item, 1);
  },
  // 返工
  rework(item) {
   // this.$refs.assignTaskView.openSet(item,2);
   this.$confirm("该任务不合格，需返工?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     this.reworkReq(item);
    })
    .catch(() => {});
  },
  // 返工
  reworkReq(item) {
   let data = {};
   data.orderId = item.orderId;
   data.orderStatus = 3;
   data.employeeId = item.employeeId;
   console.log(data);
   updateOrderDetail(data)
    .then((res) => {
     console.log(res);
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("已通知处理人返工");
      this.showDialog = false;
      this.getList();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     console.log(err);
     this.loading = false;
    });
  },
  del(item) {
   // this.$refs.assignTaskView.openSet(item,2);
   this.$confirm("是否要删除此工单?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     this.delReq(item.orderId);
    })
    .catch(() => {});
  },
  delReq(id) {
   deleteWorkOrder(id)
    .then((res) => {
     console.log(res);
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("删除成功");
      this.showDialog = false;
      this.getList();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     console.log(err);
     this.loading = false;
    });
  },

  openpopup() {
   this.$refs.add.openSet();
  },

  // 根据值显示信息
  showLabelText(val, list) {
   return showLabel(val, list);
  },

  initialize() {
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
    }
   });
  },
  getSearchData() {
   this.listQuery = {};
   for (let key in this.searchForm) {
    if (key == "times") {
     if (this.searchForm.times) {
      this.listQuery.startTime = this.searchForm.times[0];
      this.listQuery.endTime = this.searchForm.times[1];
     } else {
      this.listQuery.startTime = this.listQuery.endTime = null;
     }
    } else if (this.searchForm[key] || this.searchForm[key] === 0) {
     this.listQuery[key] = this.searchForm[key];
    }
   }
   console.log(this.listQuery, "this.listQuery");
  },
  // 获取门店下拉框 -- 2
  selectOne(id) {
   this.searchForm.hotelId = null;
   this.searchForm.roomNumber = null;
   this.hotelSelectData = null;
   getHotelSelect(id).then((res) => {
    if (res.data.data) {
     this.hotelSelectData = res.data.data;
    } else {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   });
  },
  // 搜索
  handleSearch() {
   this.pageNum = 1;
   this.getSearchData();
   this.getList();
  },
  // 重置
  reset() {
   this.pageNum = 1;
   this.searchForm = {};
   this.getSearchData();
   this.getList();
  },
  handleSizeChange(val) {
   this.pageSize = val;
   this.getList();
  },
  handleCurrentChange(val) {
   this.pageNum = val;
   this.getList();
  },
  // 列表数据
  getList() {
   this.loading = true;
   getWorkOrderList(this.pageNum, this.pageSize, this.listQuery)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data.records;
      this.total = res.data.data.total;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
 },
};
</script>
<style lang="scss" scoped >
.my-box {
 .my-card {
  margin-bottom: 10px;
 }

 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;
  .el-input__inner {
   max-width: 190px;
  }
  span {
   font-size: 14px;
  }
 }
 
}
</style>


